import React from "react";
import Arrow from "../Images/arrow-white.svg";
import NEWS from "../Images/NEWS.png";
import NEWSvertical from "../Images/NEWS-vertical.png";
import NEWS2 from "../Images/news2.png";
import helsinki from "../Images/helsinki.png";

function NewsPisteytysPage({ setNewsPisteytysVisibility, visibility, setCareType,
  careType }) {
  return (
    <div
      className={
        "emergencyPage-container" +
        (typeof visibility === "boolean"
          ? visibility
            ? " active"
            : " inactive"
          : "")
      }
    >
      <div className="wave-blue">
        <img
          src={Arrow}
          alt="arrow.svg"
          onClick={() => setNewsPisteytysVisibility(false)}
        ></img>
        <h2 className="responsiveh2">NEWS - Aikaisen varoituksen pisteytysjärjestelmä</h2>
        <img className="helsinki"
          src={helsinki}
          alt=""
        ></img>
      </div>
      {/* <div className="hatatilanne-container">
        <div className="hatatilanne-image">
          <img className="image1" src={NEWS} alt="" />
          <img className="image2" src={NEWSvertical} alt="" />
        </div>
      </div> */}
      {/* update the image */}
      <div style={{ marginTop: '150px' }}>
        <img src={NEWS2} alt="" style={{ maxWidth: "100%", height: "auto" }} />
      </div>

    </div>
  );
}

export default NewsPisteytysPage;


