import React from "react";
import Arrow from "../Images/arrow-white.svg";
import ABCDE from "../Images/ABCDE.png";
import ABCDEvertical from "../Images/ABCDE-vertical.png";
import ABCDE2 from "../Images/ABCDE_update.png"
import helsinki from "../Images/helsinki.png";

function ABCDEPage({ setABCDEVisibility, visibility, careType }) {
  // console.log(careType)

  return (
    <div
      className={
        "emergencyPage-container" +
        (typeof visibility === "boolean"
          ? visibility
            ? " active"
            : " inactive"
          : "")
      }
    >
      <div className="wave-blue">
        <img
          src={Arrow}
          alt=""
          onClick={() => setABCDEVisibility(false)}
        ></img>
        <h2 className="responsiveh2">ABCDE-menetelmä ja sen käyttö</h2>
        <img className="helsinki"
          src={helsinki}
          alt=""
        ></img>
      </div>

      {/* update the image */}
      <div style={{ marginTop: '150px' }}>
        <img src={ABCDE2} alt="" style={{ maxWidth: "100%", height: "auto" }} />
      </div>

    </div>

  );
}

export default ABCDEPage;



