// import React from "react";

// //This is the instruction for kotihoito and Ympärivuorokautinen hoiva
// var EmergencySymptomsContent = {


//   aivoverenkierronhairio: {
//     Kotihoito: {
//       header: "Aivoverenkierronhäiriö",
//       span: "(Uusi oire) ",

//       instructions: (
//         <>
//           ● toinen käsi tai jalka ei toimi normaalisti
//           (halvaus)<br></br>
//           ● toinen käsi puristaa heikommin<br></br>
//           ● suun toinen puoli on alempana
//           (suupieli roikkuu)<br></br>
//           <div style={{ marginTop: '4px', marginBottom: '4px' }}>
//             Oireet ovat tällä hetkellä tai olivat hetki
//             sitten.<br></br>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita 112
//           </div><br></br>
//           Aivoverenkiertohäiriön oireita voivat
//           olla myös:<br></br>
//           ● näkee epäselvästi (näköhäiriö)<br></br>
//           ● voi pahoin tai oksentaa<br></br>
//           ● puhuu epäselvästi<br></br>
//           ● käyttäytyy sekavasti<br></br>
//           <div style={{ marginTop: '2px', marginBottom: '4px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet.
//             Sitten konsultoi lääkäriä.
//           </div><br></br>
//           Virka-ajan ulkopuolella soita erillisen kon-sultointiohjeen mukaan. (Konsultointiohje
//           = Päivystyksellisten tilanteiden konsultointi
//           ja yhteistyö Liikkuvan hoitajan kanssa
//           kotihoidossa ja ympärivuorokautisessa
//           hoivassa)

//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Aivoverenkierronhäiriö",
//       span: "(Uusi oire) ",

//       instructions: (
//         <>
//           ● toinen käsi tai jalka ei toimi normaalisti
//           (halvaus)<br></br>
//           ● toinen käsi puristaa heikommin<br></br>
//           ● suun toinen puoli on alempana
//           (suupieli roikkuu)<br></br>
//           <div style={{ marginTop: '4px', marginBottom: '4px' }}>
//             Oireet ovat tällä hetkellä tai olivat hetki
//             sitten<br></br>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita heti sairaanhoitajalle
//             tai lääkärille. Virka-ajan ulkopuolella soita
//             erillisen konsultointiohjeen mukaan.
//           </div><br></br>
//           Aivoverenkiertohäiriön oireita voivat
//           olla myös:<br></br>
//           ● näkee epäselvästi (näköhäiriö)<br></br>
//           ● voi pahoin tai oksentaa<br></br>
//           ● puhuu epäselvästi<br></br>
//           ● käyttäytyy sekavasti<br></br>
//           <div style={{ marginTop: '2px', marginBottom: '4px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita sairaanhoitajalle tai
//             lääkärille. Virka-ajan ulkopuolella soita
//             erillisen konsultointiohjeen mukaan.
//             (Konsultointiohje = Päivystyksellisten
//             tilanteiden konsultointi ja yhteistyö
//             Liikkuvan hoitajan kanssa kotihoidossa
//             ja ympärivuorokautisessa hoivassa)
//           </div>
//         </>
//       )
//     }
//   },
//   elottomuus: {
//     Kotihoito: {
//       header: "Elottomuus",
//       span: " ",

//       instructions: (
//         <>
//           Ei reagoi, mihinkään ja:<br></br>
//           &rarr;{" "}  ei hengitä tai<br></br>
//           &rarr;{" "}   hengittää harvoin (alle 8 kertaa minuutissa) tai<br></br>
//           &rarr;{" "}   ei hengitä säännöllisesti tai<br></br>
//           &rarr;{" "}  engittää äänekkäästi (korina).<br></br>

//           <div style={{ padding: '2px' }}>
//             <span style={{ color: 'red', fontWeight: "550" }}> Soita 112</span> ja aloita elvytys hätäkeskuksen ohjeiden mukaan.<br></br>
//           </div>
//           <span style={{ fontWeight: "500" }}>
//             HUOM: Jos asiakkaalla on DNR-päätös
//             (elvytyskielto), älä aloita elvytystä. Älä
//             soita 112. Virka-aikana soita omalle lääkärille. Virka-ajan ulkopuolella toimi erillisen
//             ohjeen mukaan (Kuoleman toteaminen
//             kotihoidossa).
//           </span>{" "}

//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Elottomuus",
//       span: " ",

//       instructions: (
//         <>
//           Ei reagoi, mihinkään ja:<br></br>
//           &rarr;{" "}  ei hengitä tai<br></br>
//           &rarr;{" "}   hengittää harvoin (alle 8 kertaa minuutissa) tai<br></br>
//           &rarr;{" "}   ei hengitä säännöllisesti tai<br></br>
//           &rarr;{" "}  engittää äänekkäästi (korina).<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
//             Jos asiakkaalla on DNR-päätös (elvyty-skielto), älä aloita elvytystä. Älä soita 112.
//             Virka-aikana soita omalle lääkärille.
//             Virka-ajan ulkopuolella toimi erillisen
//             ohjeen mukaan (Kuoleman toteaminen
//             ympärivuorokautisessa hoitopaikassa). <br></br>
//           </div>
//           Jos asiakkaalla ei ole DNR-päätöstä:
//           soita 112 ja aloita elvytys.
//         </>
//       )
//     }
//   },


//   hengenahdistus: {
//     Kotihoito: {
//       header: "Keuhkot ja hengitys",
//       span: " ",

//       instructions: (
//         <>
//           <span style={{ fontWeight: "500" }}>
//             Vakavammat oireet
//           </span>{" "}<br></br>
//           Asiakkaalla on ainakin yksi näistä oireista:<br></br>
//           ● hengenahdistus alkaa yhtäkkiä<br></br>
//           ● hengittää nopeasti, yli 25 kertaa
//           minuutissa. <br></br>
//           ● hengittää apuhengityslihaksilla eli hengittää myös vatsan ja kaulan alueen lihaksilla<br></br>
//           ● puhuu vain yhden sanan kerrallaan tai
//           puhuminen on vaikeaa.<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Anna tarvittava lääke (inhalaatio) lääkelistan ohjeen mukaan, jos asiakkaalla on
//             sellainen. Jos lääke auttaa, soita hoitajalle
//             tai lääkärille. Jos lääke ei auta, soita 112.
//             Jos lääkettä ei ole, soita 112.
//           </div><br></br>
//           <span style={{ fontWeight: "500" }}>
//             Lievemmät oireet
//           </span>{" "}<br></br>
//           ● uutena oireena hengenahdistus ja
//           hengittää nopeasti (21-24 kertaa
//           minuutissa), mutta pystyy puhumaan
//           lyhyitä lauseita<br></br>
//           ● hengittää hyvin harvoin (9-11 kertaa
//           minuutissa) ja on myös muita oireita,
//           esimerkiksi sairauskohtaus<br></br>
//           ● keuhkosairauden paheneminen niin,
//           että on enemmän oireita ja SpO2 on
//           matala. (esimerkiksi COPD tai astma)
//           ● uutena oireena yskä tai märkäiset
//           yskökset<br></br>
//           ● yskä on jatkunut pitkään erityisesti
//           aamuisin ja öisin<br></br>
//           <span style={{ fontWeight: "500" }}>
//             HUOM: Jos asiakkaalla on DNR-päätös
//             (elvytyskielto), älä aloita elvytystä. Älä
//             soita 112. Virka-aikana soita omalle lääkärille. Virka-ajan ulkopuolella toimi erillisen
//             ohjeen mukaan (Kuoleman toteaminen
//             kotihoidossa).<br></br>
//           </span>{" "}
//           Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet.
//           Soita sairaanhoitajalle tai lääkärille.
//           Virka-ajan ulkopuolella soita erillisen
//           konsultointiohjeen mukaan.
//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Keuhkot ja hengitys",
//       span: " ",

//       instructions: (
//         <>
//           <span style={{ fontWeight: "500" }}>
//             Vakavammat oireet
//           </span>{" "}<br></br>
//           Asiakkaalla on ainakin yksi näistä oireista:<br></br>
//           ● hengenahdistus alkaa yhtäkkiä<br></br>
//           ● hengittää nopeasti, yli 25 kertaa
//           minuutissa. <br></br>
//           ● hengittää apuhengityslihaksilla eli hengittää myös vatsan ja kaulan alueen lihaksilla<br></br>
//           ● puhuu vain yhden sanan kerrallaan tai
//           puhuminen on vaikeaa.<br></br>
//           <div style={{ paddingTop: '10px' }}>
//             Anna tarvittava lääke (inhalaatio) lääkelistan
//             ohjeen mukaan, jos asiakkaalla on sellainen.Jos lääke auttaa, soita hoitajalle tai lääkärille.
//             Jos lääke ei auta, tai sitä ei ole, soita heti
//             sairaanhoitajalle tai lääkärille. Virka-ajan
//             ulkopuolella soita erillisen konsultointi-ohjeen mukaan.
//           </div>
//           <span style={{ fontWeight: "500" }}>
//             Lievemmät oireet
//           </span>{" "}<br></br>
//           ● uutena oireena hengenahdistus ja
//           hengittää nopeasti (21-24 kertaa
//           minuutissa), mutta pystyy puhumaan
//           lyhyitä lauseita<br></br>
//           ● hengittää hyvin harvoin (9-11 kertaa
//           minuutissa) ja on myös muita oireita,
//           esimerkiksi sairauskohtaus<br></br>
//           ● keuhkosairauden paheneminen niin,
//           että on enemmän oireita ja SpO2 on
//           matala (esimerkiksi COPD tai astma)<br></br>
//           ● uutena oireena yskä tai märkäiset
//           yskökset<br></br>
//           ● yskä on jatkunut pitkään erityisesti
//           aamuisin ja öisin<br></br>
//           ● huom. vertaa happisaturaatiota SpO2
//           aiempiin tuloksiin. Jos sormet ovat viileät
//           tai kylmät, SpO2 on tavallista alhaisempi.<br></br>
//           <div style={{ paddingTop: '10px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita sairaanhoitajalle tai
//             lääkärille. Virka-ajan ulkopuolella soita
//             erillisen konsultointiohjeen mukaan.
//           </div>

//         </>
//       )
//     }
//   },



//   hyperglykemia: {
//     Kotihoito: {
//       header: "Hyperglykemia",
//       span: "(Kohonnut verensokeri) ",

//       instructions: (
//         <>
//           <span style={{ fontWeight: "500" }}>
//             Vakavammat oireet
//           </span>{" "}<br></br>
//           Verensokeri yli 25 mmol / litra tai
//           mittarin näytössä lukee ”HI” tai ”High”<br></br>
//           ● janottaa ja väsyttää<br></br>
//           ● ei jaksa puhua tai avata silmiä<br></br>
//           ● käy usein vessassa<br></br>
//           ● kuivumisen merkkejä:<br></br>
//           &rarr;{" "} kieli on kuiva<br></br>
//           &rarr;{" "} iho jää poimulle nipistämisen jälkeen<br></br>
//           ● on tokkurainen<br></br>
//           ● hengitys haisee alkoholilta (asetoni).<br></br>
//           <div style={{ paddingTop: '8px', paddingBottom: '5px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Konsultoi heti lääkäriä.
//             Jos et tavoita lääkäriä, soita 112.
//           </div><br></br>
//           <span style={{ fontWeight: "500" }}>
//             Lievemmät oireet
//           </span>{" "}<br></br>
//           ● verensokeri kahtena päivänä peräkkäin
//           15-20 mmol / litra<br></br>

//           <div style={{ paddingTop: '10px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita sairaanhoitajalle tai
//             lääkärille. Virka-ajan ulkopuolella soita
//             erillisen konsultointiohjeen mukaan.
//           </div>
//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Hyperglykemia",
//       span: "(Kohonnut verensokeri) ",

//       instructions: (
//         <>
//           <span style={{ fontWeight: "500" }}>
//             Vakavammat oireet
//           </span>{" "}<br></br>
//           Verensokeri yli 25 mmol / litra tai
//           mittarin näytössä lukee ”HI” tai ”High”<br></br>
//           ● janottaa ja väsyttää<br></br>
//           ● ei jaksa puhua tai avata silmiä<br></br>
//           ● käy usein vessassa<br></br>
//           ● kuivumisen merkkejä:<br></br>
//           &rarr;{" "} kieli on kuiva<br></br>
//           &rarr;{" "} iho jää poimulle nipistämisen jälkeen<br></br>
//           ● on tokkurainen<br></br>
//           ● hengitys haisee alkoholilta (asetoni).<br></br>
//           <div style={{ paddingTop: '10px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Konsultoi heti lääkäriä.
//             Virka-ajan ulkopuolella soita erillisen
//             konsultointiohjeen mukaan.
//           </div><br></br>
//           <span style={{ fontWeight: "500" }}>
//             Lievemmät oireet
//           </span>{" "}<br></br>
//           ● verensokeri kahtena päivänä peräkkäin
//           15-20 mmol / litra <br></br>

//           <div style={{ paddingTop: '10px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita sairaanhoitajalle tai
//             lääkärille. Virka-ajan ulkopuolella soita
//             erillisen konsultointiohjeen mukaan.
//           </div>

//         </>
//       )
//     }
//   },

//   hypoglykemia: {
//     Kotihoito: {
//       header: "Hypoglykemia",
//       span: "(Alhainen verensokeri) ",

//       instructions: (
//         <>
//           <span style={{ fontWeight: "500" }}>
//             Vakavammat oireet
//           </span>{" "}<br></br>
//           ● verensokeri alle 4 mmol / litra<br></br>
//           ● lisäksi voimakkaat oireet: väsyttää paljon,<br></br>
//           ei jaksa puhua tai avata silmiä<br></br>
//           ● asiakas ei reagoi tai on tajuton<br></br>
//           <div style={{ paddingTop: '8px', paddingBottom: '5px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita 112 ja noudata
//             hätäkeskuksen ohjeita.
//           </div><br></br>
//           <span style={{ fontWeight: "500" }}>
//             Lievemmät oireet
//           </span>{" "}<br></br>
//           ● Verensokeri alle 4 mmol / litra ja on
//           lieviä oireita:<br></br>
//           &rarr;{" "} on ärtyisä<br></br>
//           &rarr;{" "} hikoilee<br></br>
//           &rarr;{" "} vapisee<br></br>
//           &rarr;{" "} on nälkäinen<br></br>

//           <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
//             Anna heti sokeripitoista juotavaa tai
//             ruokaa. Sitten soita heti sairaanhoitajalle
//             tai lääkärille. Virka-ajan ulkopuolella toimi
//             erillisen konsultointiohjeen mukaan.<br></br>
//           </div><br></br>
//           ● Verensokeri on kahtena päivänä
//           peräkkäin 4-6 mmol / litra.<br></br>
//           <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet.
//             Soita heti sairaanhoitajalle tai lääkärille.
//             Virka-ajan ulkopuolella soita erillisen
//             konsultointiohjeen mukaan.
//           </div>
//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Hypoglykemia",
//       span: "(Alhainen verensokeri) ",

//       instructions: (
//         <>
//           <span style={{ fontWeight: "500" }}>
//             Vakavammat oireet
//           </span>{" "}<br></br>
//           ● verensokeri alle 4 mmol / litra<br></br>
//           ● lisäksi voimakkaat oireet:<br></br>
//           väsyttää paljon, ei jaksa puhua tai
//           avata silmiä<br></br>
//           ● asiakas ei reagoi tai on tajuton.<br></br>
//           <div style={{ paddingTop: '10px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita heti lääkärille.
//             Virka-ajan ulkopuolella soita erillisen
//             konsultointiohjeen mukaan.
//           </div><br></br>
//           <span style={{ fontWeight: "500" }}>
//             Lievemmät oireet
//           </span>{" "}<br></br>
//           ● Verensokeri alle 4 mmol / litra ja on
//           lieviä oireita:<br></br>
//           &rarr;{" "} on ärtyisä<br></br>
//           &rarr;{" "} hikoilee<br></br>
//           &rarr;{" "} vapisee<br></br>
//           &rarr;{" "} on nälkäinen<br></br>

//           <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
//             Anna heti sokeripitoista juotavaa tai
//             ruokaa. Sitten soita heti sairaanhoitajalle
//             tai lääkärille. Virka-ajan ulkopuolella toimi
//             erillisen konsultointiohjeen mukaan.<br></br>
//           </div><br></br>
//           ● Verensokeri on kahtena päivänä
//           peräkkäin 4-6 mmol / litra.<br></br>
//           <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet.
//             Soita heti sairaanhoitajalle tai lääkärille.
//             Virka-ajan ulkopuolella soita erillisen
//             konsultointiohjeen mukaan.
//           </div>

//         </>
//       )
//     }
//   },

//   kouristaminen: {
//     Kotihoito: {
//       header: "Kouristaminen",
//       span: " ",

//       instructions: (
//         <>
//           ● kädet, jalat ja vartalo kouristelee<br></br>
//           ● suusta voi tulla limaa tai vaahtoa<br></br>
//           ● ei ole kontaktissa.<br></br>
//           <div style={{ padding: '2px' }}>
//             <span style={{ color: 'red', fontWeight: "550" }}> Soita 112</span> ja noudata hätäkeskuksen
//             ohjeita.
//           </div>
//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Kouristaminen",
//       span: " ",

//       instructions: (
//         <>
//           ● kädet, jalat ja vartalo kouristelee<br></br>
//           ● suusta voi tulla limaa tai vaahtoa<br></br>
//           ● ei ole kontaktissa.<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
//             Soita heti sairaanhoitajalle tai lääkärille.
//             Virka-ajan ulkopuolella soita erillisen
//             konsultointiohjeen mukaan.
//           </div>
//         </>
//       )
//     }
//   },

//   myrkytysJaTajunta: {
//     Kotihoito: {
//       header: "Myrkytys",
//       span: " ",

//       instructions: (
//         <>
//           <span style={{ fontWeight: "500" }}>
//             Vakavammat oireet
//           </span>{" "}<br></br>
//           ● alentunut tajunnan taso<br></br>
//           &rarr;{" "} reagoi huonosti<br></br>
//           &rarr;{" "} ei jaksa vastata. <br></br>
//           <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
//             Erityisen vaarallisia lääkkeitä liikaa otettuna:
//           </div><br></br>
//           <span style={{ fontWeight: "500" }}>
//             1. psykoosilääkkeet
//           </span> (vaikuttava aine
//           esimerkiksi ketiapiini, risperidoni,
//           klotsapiini, litium)<br></br>
//           <span style={{ fontWeight: "500" }}>
//             2. rauhoittavat lääkkeet
//           </span>  (vaikuttava aine
//           esimerkiksi diatsepaami, oksatsepaami,
//           tsopikloni)<br></br>
//           <span style={{ fontWeight: "500" }}>
//             3. lääkkeet, jotka hidastavat sydämen
//             rytmiä
//           </span>  (vaikuttava aine esimerkiksi
//           bisoprololi, digoksiini, diltiatseemi)<br></br>
//           <span style={{ fontWeight: "500" }}>
//             4. vahvat kipulääkkeet
//           </span>  (vaikuttava aine
//           esimerkiksi oksikodoni, tramadoli,
//           fentanyyli) <br></br>
//           <span style={{ fontWeight: "500" }}>
//             5. lääkkeet, jotka alentavat verenpainetta
//           </span>
//           (vaikuttava aine esimerkiksi amlodipiini
//           tai diltiatseemi)<br></br>
//           <span style={{ fontWeight: "500" }}>
//             6.lääkkeet, jotka laskevat verensokeria
//           </span>
//           (vaikuttava aine esimerkiksi glargin-insuliini, aspartinsuliini)<br></br>
//           <span style={{ fontWeight: "500" }}>
//             7. lääkkeet, jotka ohentavat verta
//           </span>
//           (vaikuttava aine esimerkiksi
//           varfariini, apiksabaani, enoksabaani,
//           rivaroksabaani, hepariini).<br></br>
//           <span style={{ fontWeight: "500" }}>
//             Muista tarkastaa myös kipulääkkeet,
//             joita saa ilman reseptiä: (vaikuttava
//             aine parasetamoli, ibuprofeeni tai
//             asetyylisalisyylihappo).<br></br>
//           </span>
//           Tarkasta, paljonko asiakas on ottanut
//           lääkettä vuorokauden aikana. Tarkasta
//           peruselintoiminnot ABCDE-menetelmällä
//           ja laske NEWS-pisteet. Soita 112.<br></br>


//           <span style={{ fontWeight: "500" }}>
//             Lievemmät oireet
//           </span>{" "}<br></br>
//           ● voi pahoin tai oksentaa<br></br>
//           ● vitaalimittauksista tulee tavallista<br></br>
//           ● alhaisempia tai korkeampia tuloksia<br></br>
//           ● myrkytystila ilman oireita eli<br></br>
//           ● asiakas on esimerkiksi ottanut <br></br>
//           liikaa lääkkeitä<br></br>
//           ● myrkytyksen syynä päihteet.<br></br>

//           <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet.
//             Soita heti sairaanhoitajalle tai lääkärille.
//             Virka-ajan ulkopuolella soita erillisen
//             konsultointiohjeen mukaan.
//           </div><br></br>

//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Myrkytys",
//       span: " ",

//       instructions: (
//         <>
//           <span style={{ fontWeight: "500" }}>
//             Vakavammat oireet
//           </span>{" "}<br></br>
//           ● alentunut tajunnan taso<br></br>
//           &rarr;{" "} reagoi huonosti<br></br>
//           &rarr;{" "} ei jaksa vastata <br></br>
//           <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
//             Erityisen vaarallisia lääkkeitä liikaa otettuna:
//           </div><br></br>
//           <span style={{ fontWeight: "500" }}>
//             1. psykoosilääkkeet
//           </span> (vaikuttava aine
//           esimerkiksi ketiapiini, risperidoni,
//           klotsapiini, litium)<br></br>
//           <span style={{ fontWeight: "500" }}>
//             2. rauhoittavat lääkkeet
//           </span>  (vaikuttava aine
//           esimerkiksi diatsepaami, oksatsepaami,
//           tsopikloni)<br></br>
//           <span style={{ fontWeight: "500" }}>
//             3. lääkkeet, jotka hidastavat sydämen
//             rytmiä
//           </span>  (vaikuttava aine esimerkiksi
//           bisoprololi, digoksiini, diltiatseemi)<br></br>
//           <span style={{ fontWeight: "500" }}>
//             4. vahvat kipulääkkeet
//           </span>  (vaikuttava aine
//           esimerkiksi oksikodoni, tramadoli,
//           fentanyyli) <br></br>
//           <span style={{ fontWeight: "500" }}>
//             5. lääkkeet, jotka alentavat verenpainetta
//           </span>
//           (vaikuttava aine esimerkiksi amlodipiini
//           tai diltiatseemi)<br></br>
//           <span style={{ fontWeight: "500" }}>
//             6.lääkkeet, jotka laskevat verensokeria
//           </span>
//           (vaikuttava aine esimerkiksi glargin-insuliini, aspartinsuliini)<br></br>
//           <span style={{ fontWeight: "500" }}>
//             7. lääkkeet, jotka ohentavat verta
//           </span>
//           (vaikuttava aine esimerkiksi
//           varfariini, apiksabaani, enoksabaani,
//           rivaroksabaani, hepariini).<br></br>
//           <span style={{ fontWeight: "500" }}>
//             Muista tarkastaa myös kipulääkkeet,
//             joita saa ilman reseptiä: (vaikuttava
//             aine parasetamoli, ibuprofeeni tai
//             asetyylisalisyylihappo).<br></br>
//           </span>
//           Tarkasta, paljonko asiakas on ottanut
//           lääkettä vuorokauden aikana. Tarkasta
//           peruselintoiminnot ABCDE-menetelmällä.
//           Soita heti sairaanhoitajalle tai lääkärille.
//           Virka-ajan ulkopuolella soita erillisen
//           konsultointiohjeen mukaan.<br></br>
//           <span style={{ fontWeight: "500" }}>
//             Lievemmät oireet
//           </span>{" "}<br></br>
//           ● voi pahoin tai oksentaa<br></br>
//           ● vitaalimittauksista tulee tavallista<br></br>
//           ● alhaisempia tai korkeampia tuloksia<br></br>
//           ● myrkytystila ilman oireita eli<br></br>
//           ● asiakas on esimerkiksi ottanut liikaa
//           lääkkeitä<br></br>
//           ● myrkytyksen syynä päihteet.<br></br>

//           <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita heti sairaanhoitajalle
//             tai lääkärille. Virka-ajan ulkopuolella soita
//             erillisen konsultointiohjeen mukaan.
//           </div><br></br>


//         </>
//       )
//     }
//   },

//   rintakipu: {
//     Kotihoito: {
//       header: "Sydän, pulssi ja verenkierto",
//       span: " ",

//       instructions: (
//         <>
//           <span style={{ fontWeight: "500" }}>
//             Vakavammat oireet
//           </span>{" "}<br></br>
//           ● kipu rinnassa alkaa yhtäkkiä<br></br>
//           ● puristava tunne rinnassa<br></br>
//           ● kipua voi tuntua myös vasemmassa
//           kädessä,<br></br>
//           ● kaulassa, leuassa tai selässä<br></br>
//           ● iho on hikinen ja kylmä.<br></br>
//           <div style={{ paddingTop: '6px', paddingBottom: '6px' }}>
//             Anna tarvittava lääke lääkelistan ohjeen
//             mukaan, jos asiakkaalla on sellainen.
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet.
//             Vaikka lääke auttaisi, soita heti sairaan-hoitajalle tai lääkärille saadaksesi jatko-ohjeet. Virka-ajan ulkopuolella soita erillisen konsultointiohjeen mukaan. Jos
//             lääke ei auta, soita 112. Jos lääkettä ei ole,
//             soita 112.
//           </div><br></br>
//           <span style={{ fontWeight: "500" }}>
//             Lievemmät oireet
//           </span>{" "}<br></br>
//           ● lyhyt rintakipu, joka on ollut usein viime
//           viikkojen aikana<br></br>
//           ● uutena oireena lyhyt rintakipu, joka on
//           mennyt ohi<br></br>
//           ● uutena oireena pulssi on epätasainen<br></br>
//           ● pulssi on 91-130 kertaa minuutissa ja se
//           on ennen ollut matalampi
//           <span style={{ fontWeight: "500" }}>
//             Huom:
//           </span> oireita ei tarvitse olla <br></br>
//           ● pulssi on 41-50 kertaa minuutissa ja se
//           on ennen ollut korkeampi <br></br>
//           <span style={{ fontWeight: "500" }}>
//             Huom:
//           </span> oireita ei tarvitse olla<br></br>
//           ● verenpaine muuttunut<br></br>
//           &rarr;{" "} yläpaine on matala (91-110)<br></br>
//           &rarr;{" "} yläpaine on korkea useana päivänä
//           (yli 200) <br></br>
//           &rarr;{" "} lisäksi on muita oireita: pyörryttää,
//           huimaa, väsyttää tai pää on kipeä<br></br>
//           ● sydämen vajaatoiminnan paheneminen<br></br>
//           &rarr;{" "} molemmissa jaloissa on uutta turvotus-ta ja paino on noussut 2-3 kg viikossa<br></br>
//           &rarr;{" "} asiakas on hengästynyt.<br></br>

//           <span style={{ paddingTop: "6px", paddingBottom: '10px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet.
//             Soita heti sairaanhoitajalle tai lääkärille.
//             Virka-ajan ulkopuolella soita erillisen
//             konsultointiohjeen mukaan.
//           </span>{" "}

//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Sydän, pulssi ja verenkierto",
//       span: " ",

//       instructions: (
//         <>
//           <span style={{ fontWeight: "500" }}>
//             Vakavammat oireet
//           </span>{" "}<br></br>
//           ● kipu rinnassa alkaa yhtäkkiä<br></br>
//           ● puristava tunne rinnassa<br></br>
//           ● kipua voi tuntua myös vasemmassa
//           kädessä,<br></br>
//           ● kaulassa, leuassa tai selässä<br></br>
//           ● iho on hikinen ja kylmä.<br></br>
//           <div style={{ paddingTop: '6px', paddingBottom: '6px' }}>
//             Anna tarvittava lääke lääkelistan ohjeen
//             mukaan, jos asiakkaalla on sellainen.
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Vaikka lääke auttaisi, soita
//             heti sairaanhoitajalle tai lääkärille saadak-sesi jatko-ohjeet. Jos lääke ei auta, tai
//             sitä ei ole, soita heti sairaanhoitajalle tai
//             lääkärille. Virka-ajan ulkopuolella soita
//             erillisen konsultointiohjeen mukaan.
//           </div><br></br>
//           <span style={{ fontWeight: "500" }}>
//             Lievemmät oireet
//           </span>{" "}<br></br>
//           ● lyhyt rintakipu, joka on ollut usein viime
//           viikkojen aikana<br></br>
//           ● uutena oireena lyhyt rintakipu, joka on
//           mennyt ohi<br></br>
//           ● uutena oireena pulssi on epätasainen<br></br>
//           ● pulssi on 91-130 kertaa minuutissa ja se
//           on ennen ollut matalampi
//           <span style={{ fontWeight: "500" }}>
//             Huom:
//           </span> oireita ei tarvitse olla <br></br>
//           ● pulssi on 41-50 kertaa minuutissa ja se
//           on ennen ollut korkeampi <br></br>
//           <span style={{ fontWeight: "500" }}>
//             Huom:
//           </span> oireita ei tarvitse olla<br></br>
//           ● verenpaine muuttunut<br></br>
//           &rarr;{" "} yläpaine on matala (91-110)<br></br>
//           &rarr;{" "} yläpaine on korkea useana päivänä
//           (yli 200) <br></br>
//           &rarr;{" "} lisäksi on muita oireita: pyörryttää,
//           huimaa, väsyttää tai pää on kipeä<br></br>
//           ● sydämen vajaatoiminnan paheneminen<br></br>
//           &rarr;{" "} molemmissa jaloissa on uutta turvotus-ta ja paino on noussut 2-3 kg viikossa<br></br>
//           &rarr;{" "} asiakas on hengästynyt.<br></br>

//           <span style={{ paddingTop: "6px", paddingBottom: '10px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita heti sairaanhoitajalle
//             tai lääkärille. Virka-ajan ulkopuolella soita
//             erillisen konsultointiohjeen mukaan.
//           </span>{" "}

//         </>
//       )
//     }
//   },

//   tajuton: {
//     Kotihoito: {
//       header: "Tajuttomuus",
//       span: " ",

//       instructions: (
//         <>
//           ● ei reagoi puheeseen <br></br>
//           ● ei reagoi kosketukseen tai kipuun<br></br>
//           ● hengittää normaalisti<br></br>
//           ● sydän lyö.<br></br>
//           <div style={{ paddingTop: '6px', paddingBottom: '6px' }}>
//             Käännä kylkiasentoon ja avaa hengitystiet
//             eli nosta leukaa ja paina otsaa. Tarkasta
//             peruselintoiminnot ABCDE-menetelmällä.
//             Soita 112.
//           </div>
//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Tajuttomuus",
//       span: " ",

//       instructions: (
//         <>
//           ● ei reagoi puheeseen <br></br>
//           ● ei reagoi kosketukseen tai kipuun<br></br>
//           ● hengittää normaalisti<br></br>
//           ● sydän lyö.<br></br>
//           <div style={{ paddingTop: '6px', paddingBottom: '10px' }}>
//             Käännä kylkiasentoon ja avaa hengitystiet
//             eli nosta leukaa ja paina otsaa. Tarkasta
//             peruselintoiminnot ABCDE-menetelmällä.
//             Soita heti sairaanhoitajalle tai lääkärille.
//             Virka-ajan ulkopuolella soita erillisen
//             konsultointiohjeen mukaan.
//           </div>
//         </>
//       )
//     }
//   },




//   trauma: {
//     Kotihoito: {
//       header: "Trauma ",
//       span: " ",

//       instructions: (
//         <>
//           <span style={{ fontWeight: "500" }}>
//             Vakavammat oireet
//           </span>{" "}<br></br>
//           ● ei pääse ylös (esimerkiksi jalka ei toimi)<br></br>
//           ● jos on lyönyt päänsä jonnekin,
//           voi reagoida huonosti puheeseen,
//           kosketukseen tai kipuun.<br></br>
//           <div style={{ paddingTop: '6px', paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet.
//             Anna kipulääke ohjeen mukaan, jos
//             asiakkaalla on sellainen. Soita 112.
//           </div><br></br>
//           <span style={{ fontWeight: "500" }}>
//             Lievemmät oireet
//           </span>{" "}<br></br>
//           ● Asiakas on kaatunut ja lyönyt päänsä.
//           Päässä on kuhmu, haava tai iho on rikki.
//           Huomioi erityisesti asiakkaat, jotka käyt-tävät verenohennuslääkettä.<br></br>

//           <span style={{ paddingTop: "6px", paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet. Jos
//             päässä on haava, puhdista ja hoida se.
//             Soita heti sairaanhoitajalle tai lääkärille.
//             Virka-ajan ulkopuolella soita erillisen
//             konsultointiohjeen mukaan.
//           </span>{" "}<br></br>
//           ● Asiakas on kaatunut tai loukannut raajaa.
//           Iho on rikki tai ihossa on haava.<br></br>
//           <span style={{ paddingTop: "6px", paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Puhdista haava ja soita heti sairaanhoitajalle. Virka-ajan ulkopuolella
//             soita erillisen konsultointiohjeen mukaan.
//           </span>{" "}<br></br>
//           ● Asiakas on kaatunut ja löytyy lattialta.<br></br>
//           &rarr;{" "} ei virheasentoja<br></br>
//           &rarr;{" "} on rauhallinen ja puhuu tavallisesti<br></br>
//           &rarr;{" "} pystyy koukistamaan raajoja<br></br>
//           &rarr;{" "} saat autettua asiakkaan seisomaan<br></br>
//           &rarr;{" "} liikkuminen on normaalia<br></br>
//           <span style={{ paddingTop: "6px", paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet. Jos
//             iho on rikki tai ihossa on haava, puhdista ja
//             hoida se. Soita tarvittaessa sairaanhoitajal-le tai lääkärille. Virka-ajan ulkopuolella soita
//             tarvittaessa erillisen konsultointiohjeen
//             mukaan. Jos haava on ainoa oire, voit soitaa suoraan Liiholle eli Liikkuvalle hoitajalle.
//           </span>

//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Trauma ",
//       span: " ",

//       instructions: (
//         <>
//           <span style={{ fontWeight: "500" }}>
//             Vakavammat oireet
//           </span>{" "}<br></br>
//           ● ei pääse ylös (esimerkiksi jalka ei toimi)<br></br>
//           ● jos on lyönyt päänsä jonnekin,
//           voi reagoida huonosti puheeseen,
//           kosketukseen tai kipuun.<br></br>
//           <div style={{ paddingTop: '6px', paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Anna kipulääke ohjeen
//             mukaan, jos asiakkaalla on sellainen.
//             Soita heti sairaanhoitajalle tai lääkärille.
//             Virka-ajan ulkopuolella soita erillisen
//             konsultointiohjeen mukaan.
//           </div><br></br>
//           <span style={{ fontWeight: "500" }}>
//             Lievemmät oireet
//           </span>{" "}<br></br>
//           ● Asiakas on kaatunut ja lyönyt päänsä.
//           Päässä on kuhmu, haava tai iho on rikki.
//           Huomioi erityisesti asiakkaat, jotka käyt-tävät verenohennuslääkettä.<br></br>

//           <span style={{ paddingTop: "6px", paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Jos päässä on haava,
//             puhdista ja hoida se. Soita heti sairaan-hoitajalle tai lääkärille. Virka-ajan ulko-puolella soita erillisen konsultointiohjeen
//             mukaan.
//           </span>{" "}<br></br>
//           ● Asiakas on kaatunut tai loukannut raajaa.
//           Iho on rikki tai ihossa on haava.<br></br>
//           <span style={{ paddingTop: "6px", paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Puhdista haava ja soita heti
//             sairaanhoitajalle. Virka-ajan ulkopuolella
//             soita erillisen konsultointiohjeen mukaan.
//           </span>{" "}<br></br>
//           ● Asiakas on kaatunut ja löytyy lattialta.<br></br>
//           &rarr;{" "} ei virheasentoja<br></br>
//           &rarr;{" "} on rauhallinen ja puhuu tavallisesti<br></br>
//           &rarr;{" "} pystyy koukistamaan raajoja<br></br>
//           &rarr;{" "} saat autettua asiakkaan seisomaan<br></br>
//           &rarr;{" "} liikkuminen on normaalia.<br></br>
//           <span style={{ paddingTop: "6px", paddingBottom: '10px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä.Jos iho on rikki tai ihossa
//             on haava, puhdista ja hoida se. Soita
//             tarvittaessa sairaanhoitajalle tai lääkärille.
//             Virka-ajan ulkopuolella soita tarvittaessa
//             erillisen konsultointiohjeen mukaan. Jos
//             haava on ainoa oire, voit soittaa suoraan
//             Liiholle eli Liikkuvalle hoitajalle.
//           </span>{" "}

//         </>
//       )
//     }
//   },




//   voimakasallergia: {
//     Kotihoito: {
//       header: "Voimakas allerginen reaktio",
//       span: " ",

//       instructions: (
//         <>
//           ● iho on kuuma tai punainen<br></br>
//           ● ihoa pistelee tai kutisee<br></br>
//           ● kasvot turpoavat<br></br>
//           ● ääni muuttuu käheäksi <br></br>
//           ● yskii välillä<br></br>
//           ● sydän sykkii nopeasti<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Anna lääke eli adrenaliinipistos,
//             esimerkiksi Epipen® ulkoreiden lihakseen
//             (im) tai Adrenalin injektioneste® 0,5 mg
//             lihakseen (im)
//           </div><br></br>
//           <div style={{ paddingBottom: '10px' }}>
//             Tarkasta peruselintoiminnot.
//             Soita aina 112.
//           </div>

//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Voimakas allerginen reaktio",
//       span: "",

//       instructions: (
//         <>
//           ● iho on kuuma tai punainen<br></br>
//           ● ihoa pistelee tai kutisee<br></br>
//           ● kasvot turpoavat<br></br>
//           ● ääni muuttuu käheäksi <br></br>
//           ● yskii välillä<br></br>
//           ● sydän sykkii nopeasti<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Anna lääke eli adrenaliinipistos,
//             esimerkiksi Epipen® ulkoreiden lihakseen
//             (im) tai Adrenalin injektioneste® 0,5 mg
//             lihakseen (im)
//           </div><br></br>
//           <div style={{ paddingBottom: '10px' }}>
//             Tarkasta peruselintoiminnot. Soita heti sai-raanhoitajalle tai lääkärille. Virka-ajan ulkopuo-lella soita erillisen konsultointiohjeen mukaan.
//           </div>
//         </>
//       )
//     }
//   },


//   voimakasverenvuoto: {
//     Kotihoito: {
//       header: "Voimakas verenvuoto",
//       span: "",

//       instructions: (
//         <>
//           ● verta vuotaa haavasta, nenästä,
//           sukuelinten alueelta tai peräaukosta<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Yritä heti saada verenvuoto loppumaan
//             painamalla tai sitomalla. Tarkasta perus-elintoiminnot ABCDE-menetelmällä.
//             Soita aina 112.
//           </div>
//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Voimakas verenvuoto",
//       span: " ",

//       instructions: (
//         <>
//           ● verta vuotaa haavasta, nenästä,
//           sukuelinten alueelta tai peräaukosta<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Yritä heti saada verenvuoto loppumaan
//             painamalla tai sitomalla. Tarkasta perus-elintoiminnot ABCDE-menetelmällä.
//             Soita heti sairaanhoitajalle tai lääkärille.
//             Virka-ajan ulkopuolella soita erillisen
//             konsultointiohjeen mukaan.
//           </div>
//         </>
//       )
//     }
//   },


//   tulehdusepäily: {
//     Kotihoito: {
//       header: "Tulehdusepäily",
//       span: "(infektio) ",

//       instructions: (
//         <>
//           ● Tulehdus voi olla eri alueilla:<br></br>
//           &rarr;{" "} keuhkoissa: hengittää nopeammin ja
//           hengityksestä kuuluu rohinaa<br></br>
//           &rarr;{" "} virtsateissä: virtsatessa kirvelee ja
//           asiakas käy usein virtsaamassa<br></br>
//           &rarr;{" "} kudoksessa tai iholla: kuumotusta,
//           punoitusta tai kipua.<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet.
//             Soita tarvittaessa sairaanhoitajalle tai
//             lääkärille. Virka-ajan ulkopuolella soita
//             tarvittaessa erillisen konsultointiohjeen
//             mukaan.
//           </div>
//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Tulehdusepäily",
//       span: "(infektio) ",


//       instructions: (
//         <>
//           ● Tulehdus voi olla eri alueilla:<br></br>
//           &rarr;{" "} keuhkoissa: hengittää nopeammin ja
//           hengityksestä kuuluu rohinaa<br></br>
//           &rarr;{" "} virtsateissä: virtsatessa kirvelee ja
//           asiakas käy usein virtsaamassa<br></br>
//           &rarr;{" "} kudoksessa tai iholla: kuumotusta,
//           punoitusta tai kipua.<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita tarvittaessa sairaan-hoitajalle tai lääkärille. Virka-ajan ulkopuo-lella soita tarvittaessa erillisen konsultoin-tiohjeen mukaan.
//             mukaan.
//           </div>
//         </>
//       )
//     }
//   },


//   turvotus: {
//     Kotihoito: {
//       header: "Alaraajan turvotus",
//       span: "",

//       instructions: (
//         <>
//           ● uutena oireena vain toinen jalka on
//           turvonnut<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet.
//             Soita sairaanhoitajalle tai lääkärille.
//             Virka-ajan ulkopuolella toimi erillisen
//             konsultointiohjeen mukaan.
//           </div>
//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Alaraajan turvotus",
//       span: "",

//       instructions: (
//         <>
//           ● uutena oireena vain toinen jalka on
//           turvonnut<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita sairaanhoitajalle tai
//             lääkärille. Virka-ajan ulkopuolella toimi
//             erillisen konsultointiohjeen mukaan.
//           </div>
//         </>
//       )
//     }
//   },


//   itsetuhoinen: {
//     Kotihoito: {
//       header: "Itsetuhoinen käytös",
//       span: "",

//       instructions: (
//         <>
//           ● asiakas aiheuttaa itselleen vaaraa <br></br>
//           ● uutena oireena sekavuus tai psyykkisen
//           sairauden nopea paheneminen<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita heti sairaanhoitajalle
//             tai lääkärille. Virka-ajan ulkopuolella toimi
//             erillisen konsultointiohjeen mukaan.
//           </div>

//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "Itsetuhoinen käytös",
//       span: "",


//       instructions: (
//         <>
//           ● asiakas aiheuttaa itselleen vaaraa <br></br>
//           ● uutena oireena sekavuus tai psyykkisen
//           sairauden nopea paheneminen<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita heti sairaanhoitajalle
//             tai lääkärille. Virka-ajan ulkopuolella toimi
//             erillisen konsultointiohjeen mukaan.
//           </div>
//         </>
//       )
//     }
//   },


//   kipu: {
//     Kotihoito: {
//       header: "Kipu",
//       span: "",

//       instructions: (
//         <>
//           ● uutena oireena kipu esimerkiksi päässä,
//           vatsassa, raajassa tai korvassa<br></br>
//           ● pitkäaikaisen kivun nopea paheneminen<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Arvioi kivun voimakkuutta asteikolla 0-10.
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä ja laske NEWS-pisteet.
//             Soita heti sairaanhoitajalle tai lääkärille.
//             Virka-ajan ulkopuolella soita erillisen
//             konsultointiohjeen mukaan.
//           </div>
//         </>
//       )
//     },
//     "Ympärivuorokautinen hoiva": {
//       header: "kipu",
//       span: " ",

//       instructions: (
//         <>
//           ● uutena oireena kipu esimerkiksi päässä,
//           vatsassa, raajassa tai korvassa<br></br>
//           ● pitkäaikaisen kivun nopea paheneminen<br></br>
//           <div style={{ paddingTop: '10px', paddingBottom: '6px' }}>
//             Arvioi kivun voimakkuutta asteikolla 0-10.
//             Tarkasta peruselintoiminnot ABCDE-menetelmällä. Soita heti sairaanhoitajalle
//             tai lääkärille. Virka-ajan ulkopuolella soita
//             erillisen konsultointiohjeen mukaan.
//           </div>
//         </>
//       )
//     }
//   },

// };

// export default EmergencySymptomsContent;


//This is the instruction for hätätilanneoireet 

import React from "react";

var EmergencySymptomsContent = {
  aivoverenkierronhairio: {
    header: "Aivoverenkierronhäiriö ",
    span: " ",

    instructions: (
      <>
        <span style={{ fontWeight: "600" }} >UUSI OIRE, oireiden alusta alle 9 h: </span>Toinen käsi tai jalka ei toimi normaalisti (halvaus), tai toinen käsi puristaa heikommin. Suun toinen puoli saattaa olla alempana (suupieli roikkuu).
      </>
    )
  },



  elottomuus: {
    Kotihoito: {
      header: "Elottomuus",
      span: " ",

      instructions: (
        <>
          Ei reagoi, ei hengitysliikkeitä tai ne ovat harvoja, epäsäännöllisiä, äänekkäitä. <span style={{ fontWeight: "600" }}>SOITA 112. HUOM: Mikäli on DNR-päätös</span>, soita omalle hoitajalle tai lääkärille, ei 112.
        </>
      )
    },
    "Ympärivuorokautinen hoiva": {
      header: "Elottomuus",
      span: " ",

      instructions: (
        <>
          Ei reagoi, ei hengitysliikkeitä tai harvoja, epäsäännöllisiä, äänekkäitä hengitysliikkeitä <span style={{ fontWeight: "600" }}>→ Soita konsultoivalle lääkärille. </span>Huomioi hoitosuunnitelma, hoidon rajaukset ja linjaukset.
          <span style={{ fontWeight: "600" }}>Mikäli potilaalla ei rajoituksia elvytyksen suhteen, soita 112 ja </span>aloita -painelu-puhalluselvytys.
        </>
      )
    }
  },

  hengenahdistus: {
    header: "Hengenahdistus",
    span: " ",

    instructions: (
      <>
        Asiakkaalla on ainakin yksi näistä oireista: hengenahdistus alkaa yhtäkkiä, hengittää nopeasti (yli 25 kertaa minuutissa), hengittää apuhengityslihaksilla (vatsan ja kaulan alueen lihakset). Puhuu vain yhden sanan kerrallaan tai puhuminen on vaikeaa.
      </>
    )
  },



  hyperglykemia: {
    Kotihoito: {
      header: "Kohonnut verensokeri ",
      span: "(hyperglykemia) ",

      instructions: (
        <>
          Verensokeri yli 25 mmol / litra tai mittarin näytössä lukee ”HI” tai ”High”. Asiakasta janottaa ja väsyttää (tokkurainen), ei jaksa puhua tai avata silmiä. Käy usein vessassa. Lisäksi kuivumisen merkkejä (kieli on kuiva, iho jää poimulle nipistämisen jälkeen). Hengitys haisee alkoholilta (asetoni). <span style={{ fontWeight: "600" }}>Konsultoi heti lääkäriä. Jos et tavoita lääkäriä, soita 112.</span>
        </>
      )
    },
    "Ympärivuorokautinen hoiva": {
      header: "Kohonnut verensokeri ",
      span: "(hyperglykemia) ",

      instructions: (
        <div style={{ paddingBottom: '6px' }}>

          Verensokeri yli 25 mmol / litra tai mittarin näytössä lukee ”HI” tai ”High”. Asiakasta janottaa ja väsyttää (tokkurainen), ei jaksa puhua tai avata silmiä. Käy usein vessassa. Lisäksi kuivumisen merkkejä (kieli on kuiva, iho jää poimulle nipistämisen jälkeen). Hengitys haisee alkoholilta (asetoni).

        </div>
      )
    }
  },

  hypoglykemia: {
    header: "Alhainen verensokeri ",
    span: "(hypoglykemia) ",

    instructions: (
      <>
        Verensokeri alle 4 mmol/l ja lisäksi{" "}
        <span style={{ fontWeight: "600" }}>voimakkaat </span>oireet: väsyttää paljon (ei jaksa puhua tai avata silmiä), tai asiakas ei reagoi tai on tajuton.
      </>
    )
  },

  kouristaminen: {
    header: "Kouristaminen ",
    span: " ",

    instructions: <>Raajat ja vartalon lihakset kouristelevat, ei kontaktia.</>
  },

  myrkytysJaTajunta: {
    header: "Myrkytys ja tajunnantason lasku tai muu löydös",
    span: " ",

    instructions: (
      <>
        Insuliinit, verenohennuslääke, rauhoittavat lääkkeet, opioidit (vahvat kipulääkkeet), beetasalpaaja (esim. Bisoprolol), kalsium-salpaaja (esim. Amlodipin, Dilzem), neuroleptit (esim. Ketipinor, Risperdal), paracetamol, NSAID-lääkkeet (mm Burana ym.), digoksiini.
      </>
    )
  },

  rintakipu: {
    header: "Rintakipu, eikä 3 nitroa auta",
    span: " ",

    instructions: (
      <>
        Äkillisesti alkanut, puristava, kylmähikinen, säteilee vasempaan käteen,
        lapojen väliin, leukaperiin.
      </>
    )
  },

  tajuton: {
    header: "Tajuttomuus",
    span: " ",

    instructions: <>
      Asiakas ei reagoi puheeseen, kosketukseen tai kipuun. Hengittää normaalisti ja sydän lyö. <span style={{ fontWeight: "600" }}>Käännä kylkiasentoon ja avaa hengitystiet</span> (nosta leukaa ja paina otsaa). Tarkasta peruselintoiminnot ABCDE-menetelmällä. </>
  },

  trauma: {
    header: "Trauma",
    span: " ",

    instructions: (
      <>
        Ei pääse ylös, ei pysty varaamaan, mahdollinen virheasento tai päähän
        kohdistuva trauma ja{" "}
        <span style={{ fontWeight: "600" }}>alentunut tajunnantaso.</span>
      </>
    )
  },

  voimakasallergia: {
    header: "Voimakas allerginen reaktio",
    span: " ",

    instructions: (
      <>
        Iho/a on kuuma tai punainen, pistelee tai kutittaa. Kasvot turpoavat ja ääni muuttuu käheäksi, yskittää. Sydän sykkii nopeasti. {" "}

        <span style={{ fontWeight: "600" }}>Anna lääke eli adrenaliinipistos, esimerkiksi Epipen® </span>{" "}

        ulkoreiden lihakseen (im) tai Adrenalin injektioneste® 0,5 mg lihakseen (im). Tarkasta peruselintoiminnot.
      </>
    )
  },


  voimakasverenvuoto: {
    header: "Voimakas verenvuoto ",
    span: " ",

    instructions: <>Haava, nenä, sukupuolialue, peräaukko.</>
  }
};

export default EmergencySymptomsContent;



