import React from "react";
import Arrow from "../Images/arrow-white.svg";
import img1 from "../Images/isbark.PNG";
import img2 from "../Images/ISBAR2.png";
import helsinki from "../Images/helsinki.png";

function ISBARPage({ setISBARVisibility, visibility, careType, setCareType }) {
  const isKotihoito = careType === "Kotihoito";

  // Define a media query for screens with a minimum width of 820px
  const mediaQuery = window.matchMedia("(min-width: 820px)");

  // Check if the media query matches
  const isWideScreen = mediaQuery.matches;

  return (
    <div
      className={
        "emergencyPage-container" +
        (typeof visibility === "boolean"
          ? visibility
            ? " active"
            : " inactive"
          : "")
      }
    >
      <div className="wave-red">
        <img
          src={Arrow}
          alt="arrow.svg"
          onClick={() => setISBARVisibility(false)}
        ></img>
        <h2 style={{ fontSize: '15px' }}>ISBAR-raportointiohje konsultoitaessa asiakkaasta</h2>
        <img className="helsinki" src={helsinki} alt=""></img>
      </div>

      {/* update the image */}
      <div style={{ marginTop: '150px' }}>
        <img
          src={isKotihoito ? img1 : img2}
          alt="ISBAR Image"
          style={{
            width: isWideScreen ? "95%" : "100%",
            display: "block",
            margin: "0 auto",
            height: '100vh'
          }}
        />
      </div>
    </div>
  );
}

export default ISBARPage;







