import React from "react";

function InstructionCardSecond({
  NEWSscoreTotal,
  personData,
  controlData,
  ControlNEWSscoreTotal,
  careType,
  setEmergencyVisibility
}) {
  const highRiskInstructions = {
    Kotihoito: (
      <div>
        <div className="content">
          <p style={{ display: "inline", marginLeft: "15px" }}>
            <span style={{ color: "rgb(171, 38, 21)", fontWeight: "800" }}>
              Konsultoi heti lääkäriä. Jos et tavoita lääkäriä, soita 112.
            </span>
          </p>
        </div>
      </div>
    ),

    "Ympärivuorokautinen hoiva": (
      <>
        <h3 style={{ marginTop: "30px" }}>Virka-aikana:</h3>
        <div className="content">
          <p style={{ fontSize: "20px" }}>&rarr;</p>
          <p style={{ display: "inline", marginLeft: "15px" }}>
            Konsultoi omaa lääkäriä <span>välittömästi</span>.
          </p>
        </div>
        <h3 style={{ marginTop: "30px" }}>Virka-ajan ulkopuolella:</h3>
        <div className="content">
          <p style={{ fontSize: "20px" }}>&rarr;</p>
          <p style={{ display: "inline", marginLeft: "15px" }}>
            Konsultoi lääkäriä erillisen ohjeen mukaan
            <br />
            <span
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                letterSpacing: "0.1rem"
              }}
            >
              (Virka-ajan ulkopuolinen konsultointiohje kotihoidon ja
              ympärivuorokautisen hoivan henkilöstölle)
            </span>
            .
            <br />
            Konsultoi <span>ISBAR</span>-raportointiohjeen mukaan.
          </p>
        </div>
      </>
    )
  };

  const highToNoRiskInstructions = {
    Kotihoito: (
      <>
        <div>
          <div className="content">
            <p style={{ display: "inline", marginLeft: "15px" }}>
              Soita heti tiimin sairaanhoitajalle. Virka-ajan ulkopuolella soita erillisen konsultointiohjeen mukaan.
            </p>
          </div>
        </div>
      </>
    ),

    "Ympärivuorokautinen hoiva": (
      <>
        <div>
          <h3 style={{ marginTop: "30px" }}>Virka-aikana:</h3>
          <div className="content">
            <p style={{ fontSize: "20px" }}>&rarr;</p>
            <p style={{ display: "inline", marginLeft: "15px" }}>
              Konsultoi <span>välittömästi</span> oman yksikön sairaanhoitajaa
              tai hoitavaa lääkäriä.
            </p>
          </div>
          <h3 style={{ marginTop: "30px" }}>Virka-ajan ulkopuolella:</h3>
          <div className="content">
            <p style={{ fontSize: "20px" }}>&rarr;</p>
            <p style={{ display: "inline", marginLeft: "15px" }}>
              Konsultoi oman yksikön sairaanhoitajaa jos paikalla tai suoraan{" "}
              <span>geriatrista lääkäriä</span> virka-ajan ulkopuolisen ohjeen
              mukaan
              <br />
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  letterSpacing: "0.1rem"
                }}
              >
                (Virka-ajan ulkopuolinen konsultointiohje kotihoidon ja
                ympärivuorokautisen hoivan henkilöstölle)
              </span>
              .
              <br />
              Konsultoi <span>ISBAR</span>-raportointiohjeen mukaan.
            </p>
          </div>
          <h3 style={{ marginTop: "30px" }}>
            Tarvittaessa jos haluat arvioida asiakasta uudestaan ennen
            mahdollista konsultaatiota, aloita sovellus alusta.
          </h3>
        </div>
      </>
    )
  };

  const CheckNEWSSCORErisk = () => {
    if (NEWSscoreTotal >= 7) {
      return "Korkea riski";
    } else if (
      personData["Tajunnan taso"] === false ||
      personData["Hengitystaajuus - NEWSscore"] === 3 ||
      personData["Happisaturaatio - NEWSscore"] === 3 ||
      personData["Systolinen verenpaine - NEWSscore"] === 3 ||
      personData["Syketaajuus - NEWSscore"] === 3 ||
      personData["Mittaa lämpötila - NEWSscore"] === 3 ||
      NEWSscoreTotal >= 5
    ) {
      return "Keskisuuri riski";
    } else if (NEWSscoreTotal >= 1 && NEWSscoreTotal <= 4) {
      return "Matala riski";
    } else {
      return "MATALA riski";
    }
  };

  const CheckControlNEWSSCORErisk = () => {
    if (ControlNEWSscoreTotal >= 7) {
      return "Korkea riski";
    } else if (
      controlData["Tajunnan taso"] === false ||
      controlData["Hengitystaajuus - NEWSscore"] === 3 ||
      controlData["Happisaturaatio - NEWSscore"] === 3 ||
      controlData["Systolinen verenpaine - NEWSscore"] === 3 ||
      controlData["Syketaajuus - NEWSscore"] === 3 ||
      controlData["Mittaa lämpötila - NEWSscore"] === 3 ||
      ControlNEWSscoreTotal >= 5
    ) {
      return "Keskisuuri riski";
    } else if (ControlNEWSscoreTotal >= 1 && ControlNEWSscoreTotal <= 4) {
      return "Matala riski";
    } else {
      return "MATALA riski";
    }
  };

  const checkForSingleThreePointCase = () => {
    if (
      personData["Tajunnan taso"] === false ||
      personData["Hengitystaajuus - NEWSscore"] === 3 ||
      personData["Happisaturaatio - NEWSscore"] === 3 ||
      personData["Systolinen verenpaine - NEWSscore"] === 3 ||
      personData["Syketaajuus - NEWSscore"] === 3 ||
      personData["Mittaa lämpötila - NEWSscore"] === 3
    ) {
      return true;
    }
  };

  const determineInstructions = () => {
    if (checkForSingleThreePointCase()) {
      // Follow high risk instructions for single 3-point case
      return (
        <div className="InstructionCard-second-container">
          <h3>Toimintaohje:</h3>
          <hr />
          {highRiskInstructions[careType]}
        </div>
      );
    } else if (NEWSscoreTotal >= 7 && ControlNEWSscoreTotal >= 7) {
      // Follow high risk instructions for NEWSscore >= 7
      return (
        <div className="InstructionCard-second-container">
          <h3>Toimintaohje:</h3>
          <hr />
          {highRiskInstructions[careType]}
        </div>
      );
    } else if (NEWSscoreTotal >= 5 && NEWSscoreTotal <= 6) {
      // Follow high risk instructions for NEWSscore between 5 and 6
      return (
        <div className="InstructionCard-second-container">
          <h3>Toimintaohje:</h3>
          <hr />
          {highRiskInstructions[careType]}
        </div>
      );
    } else if (NEWSscoreTotal >= 1 && NEWSscoreTotal <= 4) {
      // Follow low risk instructions for NEWSscore between 1 and 4
      return (
        <div className="InstructionCard-second-container">
          <h3>Toimintaohje:</h3>
          <hr />
          {highToNoRiskInstructions[careType]}
        </div>
      );
    } else {
      // Follow instructions for 0 points, low risk
      return (
        <div className="InstructionCard-second-container">
          <h3>Toimintaohje:</h3>
          <hr />
          <div className="content">
            <p style={{ display: "inline", marginLeft: "15px" }}>
              Jos asiakkaalla uusia oireita ja jos tilanne kuitenkin huolestuttaa sinua,
              voit aina soittaa sairaanhoitajalle tai lääkärille.
              Virka-ajan ulkopuolella soita erillisen konsultointiohjeen mukaan.
            </p>
          </div>
        </div>
      );
    }
  };

  return determineInstructions();
}

export default InstructionCardSecond;
















