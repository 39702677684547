import { render } from "@testing-library/react";
import React, { useState } from "react";
import DoubleButton from "../Components/DoubleButton";
import Page from "../Components/Page";
import { Route, Redirect } from "react-router-dom";

function HappisaturaatioChecking({ personData, setPersonData, careType }) {
  // console.log(careType)

  return (
    <Page title="Happisaturaatiomittari" paragraph="">
      <DoubleButton
        header={'Onko sinulla happisaturaatiomittaria?'}
        values={["Kyllä ", "Ei"]}
        personData={personData}
        setPersonData={setPersonData}
        name="happisaturaatio_kone_button_value"
      />
    </Page>
  );
}


export default HappisaturaatioChecking;

