// import React from "react";
// import Arrow from "../Images/arrow-white.svg";
// import hatatilanne from "../Images/hatatilanne.png";
// import SymptomCard from "../Components/SymptomCard";
// import ESC from "../Components/EmergencySymptomsContent";
// import helsinki from "../Images/helsinki.png";

// function EmergencyPage({ setEmergencyVisibility, visibility, careType }) {
//   // Determine the text for the <h2> element based on the careType value
//   const titleText = careType === "Kotihoito" ?
//     "Kotihoidon oirejaottelu ja toimintaohjeet"
//     : "Ympärivuorokautisen hoivan oirejaottelu ja toimintaohjeet";
//   return (
//     <div
//       className={
//         "emergencyPage-container" +
//         (typeof visibility === "boolean"
//           ? visibility
//             ? " active"
//             : " inactive"
//           : "")
//       }
//     >
//       <div className="emergencyPage-topBar">
//         <img
//           src={Arrow}
//           alt="arrow.svg"
//           onClick={() => setEmergencyVisibility(false)}
//         ></img>
//         <p style={{ color: 'white' }}>{titleText}</p>
//         <img className="helsinki"
//           src={helsinki}
//           alt=""
//         ></img>
//       </div>
//       <div className="symptoms-container">
//         <SymptomCard
//           content={
//             careType ? ESC.aivoverenkierronhairio[careType] : ESC.aivoverenkierronhairio.Kotihoito} />
//         <SymptomCard
//           content={
//             careType ? ESC.elottomuus[careType] : ESC.elottomuus.Kotihoito
//           }
//         />
//         <SymptomCard content={
//           careType ? ESC.hengenahdistus[careType] : ESC.hengenahdistus.Kotihoito} />
//         <SymptomCard content={
//           careType ? ESC.hyperglykemia[careType] : ESC.hyperglykemia.Kotihoito} />
//         <SymptomCard content={
//           careType ? ESC.hypoglykemia[careType] : ESC.hypoglykemia.Kotihoito} />
//         <SymptomCard content={
//           careType ? ESC.kouristaminen[careType] : ESC.kouristaminen.Kotihoito} />
//         <SymptomCard content={
//           careType ? ESC.myrkytysJaTajunta[careType] : ESC.myrkytysJaTajunta.Kotihoito} />
//         <SymptomCard content={
//           careType ? ESC.rintakipu[careType] : ESC.rintakipu.Kotihoito} />
//         <SymptomCard content={
//           careType ? ESC.tajuton[careType] : ESC.tajuton.Kotihoito} />
//         <SymptomCard content={
//           careType ? ESC.trauma[careType] : ESC.trauma.Kotihoito} />
//         <SymptomCard content={
//           careType ? ESC.voimakasallergia[careType] : ESC.voimakasallergia.Kotihoito} />
//         <SymptomCard content={
//           careType ? ESC.voimakasverenvuoto[careType] : ESC.voimakasverenvuoto.Kotihoito} />

//         <SymptomCard content={
//           careType ? ESC.tulehdusepäily[careType] : ESC.tulehdusepäily.Kotihoito} />
//         <SymptomCard content={
//           careType ? ESC.turvotus[careType] : ESC.turvotus.Kotihoito} />
//         <SymptomCard content={
//           careType ? ESC.itsetuhoinen[careType] : ESC.itsetuhoinen.Kotihoito} />
//         <SymptomCard content={
//           careType ? ESC.kipu[careType] : ESC.kipu.Kotihoito} />


//       </div>
//     </div>
//   );
// }

// export default EmergencyPage;



import React from "react";
import Arrow from "../Images/arrow-white.svg";
import hatatilanne from "../Images/hatatilanne.png";
import SymptomCard from "../Components/SymptomCard";
import ESC from "../Components/EmergencySymptomsContent";
import helsinki from "../Images/helsinki.png";

function EmergencyPage({ setEmergencyVisibility, visibility, careType }) {
  return (
    <div
      className={
        "emergencyPage-container" +
        (typeof visibility === "boolean"
          ? visibility
            ? " active"
            : " inactive"
          : "")
      }
    >
      <div className="emergencyPage-topBar">
        <img
          src={Arrow}
          alt="arrow.svg"
          onClick={() => setEmergencyVisibility(false)}
        ></img>
        <h2>Hätätilanneoireet</h2>
        <img className="helsinki"
          src={helsinki}
          alt=""
        ></img>
      </div>
      <div className="symptoms-container">
        <SymptomCard content={ESC.aivoverenkierronhairio} />
        <SymptomCard
          content={
            careType ? ESC.elottomuus[careType] : ESC.elottomuus.Kotihoito
          }
        />
        <SymptomCard content={ESC.hengenahdistus} />
        <SymptomCard content={
          careType ? ESC.hyperglykemia[careType] : ESC.hyperglykemia.Kotihoito} />
        <SymptomCard content={ESC.hypoglykemia} />
        <SymptomCard content={ESC.kouristaminen} />
        <SymptomCard content={ESC.myrkytysJaTajunta} />
        <SymptomCard content={ESC.rintakipu} />
        <SymptomCard content={ESC.tajuton} />
        <SymptomCard content={ESC.trauma} />

        <SymptomCard content={ESC.voimakasallergia} />
        <SymptomCard content={ESC.voimakasverenvuoto} />

      </div>
    </div>
  );
}

export default EmergencyPage;


